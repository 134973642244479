.rain_drop {
  animation-delay: calc(var(--delay) * 1s);
  animation-duration: calc(var(--duration) * 1s);
  animation-iteration-count: infinite;
  animation-name: drop;
  animation-timing-function: linear;
  height: 30px;
  left: calc(var(--left) * 1%);
  position: absolute;
  top: calc((var(--top) + 50) * -1px);
}
.rain_drop path {
  fill: #ffffff;
  opacity: var(--opacity);
  transform: scaleY(calc(var(--scale) * 1.5));
}
.rain {
  width: 100vw;
}
@-moz-keyframes drop {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
@-webkit-keyframes drop {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
@-o-keyframes drop {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
@keyframes drop {
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
